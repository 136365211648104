import cx from 'classnames';
import Trans from 'next-translate/Trans';
import { FunctionComponent } from 'react';

import {
  IconStar,
  IconHalfStar,
  IconHalfStarWhite,
  IconStarWhite,
  IconTrustpilot,
} from '../../ui/icons/start';
import BPImage from '../../ui/image';

import classes from './trustpilot.module.css';

const Trustpilot: FunctionComponent<{
  className?: string | undefined;
  backgroundColor?: string;
}> = ({ className, backgroundColor }) => {
  const count = '+20.000';
  const value = 4.7;

  return (
    <>
      <div
        className={cx(
          'flex gap-[6px]',
          'justify-center',
          'items-center my-auto',
          className,
          backgroundColor ? backgroundColor : 'bg-neutral-10'
        )}
      >
        <span className={'font-bold'}>{value}</span>
        <div className={cx('flex items-center', classes.starsContainer)}>
          {[0, 1, 2, 3].map((_, index) => {
            return backgroundColor === 'none' ? (
              <IconStarWhite
                // eslint-disable-next-line react/no-array-index-key
                key={`google-star${index}`}
                className={cx(
                  'h-4',
                  'mr-1',
                  value >= index + 1 ? 'text-bparts-100' : 'text-white',
                  backgroundColor === 'none' ? 'text-white' : ''
                )}
              />
            ) : (
              <IconStar
                // eslint-disable-next-line react/no-array-index-key
                key={`google-star${index}`}
                className={cx(
                  'h-4',
                  'mr-1',
                  value >= index + 1 ? 'text-bparts-100' : 'text-white',
                  backgroundColor === 'none' ? 'text-white' : ''
                )}
              />
            );
          })}
          {backgroundColor === 'none' ? (
            <IconHalfStarWhite
              className={cx('h-4', 'mr-1', 'text-bparts-100')}
            ></IconHalfStarWhite>
          ) : (
            <IconHalfStar className={cx('h-4', 'mr-1', 'text-bparts-100')} />
          )}
        </div>
        <BPImage
          src={`/svg/icons/google.svg`}
          className={'mx-1 h-[22px]'}
          height={22}
          width={22}
          layout="fixed"
          alt="Google Reviews"
        />
        {backgroundColor === 'none' ? (
          <BPImage
            src={`/svg/icons/trustpilot.svg`}
            className={'mx-1 h-[22px]'}
            height={22}
            width={24}
            layout="fixed"
            alt="Trustpilot Reviews"
          />
        ) : (
          <BPImage
            src={`/svg/icons/trustpilot-original.svg`}
            className={'mx-1 h-[22px]'}
            height={22}
            width={24}
            layout="fixed"
            alt="Trustpilot Reviews"
          />
        )}
        <Trans
          i18nKey={'common:google-reviews'}
          components={{
            strong: (
              <strong
                className={cx(
                  backgroundColor === 'none' ? 'text-white' : 'text-bparts-100'
                )}
              />
            ),
          }}
          values={{
            count: count,
          }}
        />
        <span className={''}>Reviews</span>
      </div>
    </>
  );
};

export default Trustpilot;
