import { FunctionComponent, HTMLAttributes } from 'react';

export const IconStar: FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = (
  props
) => (
  <svg
    {...props}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 18 17"
    fill="none"
  >
    <path
      d="M17 6.71429H10.8571L9 1L7.14286 6.71429H1L6 10.1429L4.07143 15.8571L9 12.2857L13.9286 15.8571L12 10.1429L17 6.71429Z"
      fill="currentColor"
      stroke="#359AD2"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconStarWhite: FunctionComponent<
  HTMLAttributes<HTMLOrSVGElement>
> = (props) => (
  <svg
    {...props}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 18 17"
    fill="none"
  >
    <path
      d="M17 6.71429H10.8571L9 1L7.14286 6.71429H1L6 10.1429L4.07143 15.8571L9 12.2857L13.9286 15.8571L12 10.1429L17 6.71429Z"
      fill="currentColor"
      stroke="#FFFFFF"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconHalfStar: FunctionComponent<
  HTMLAttributes<HTMLOrSVGElement>
> = (props) => {
  return (
    <svg
      {...props}
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 18 17"
      fill="none"
    >
      <defs>
        <linearGradient id="half_grad">
          <stop offset="50%" stopColor="currentColor" />
          <stop offset="50%" stopColor="#FFFFFF" stopOpacity="1" />
        </linearGradient>
      </defs>

      <path
        d="M17 6.71429H10.8571L9 1L7.14286 6.71429H1L6 10.1429L4.07143 15.8571L9 12.2857L13.9286 15.8571L12 10.1429L17 6.71429Z"
        fill="url(#half_grad)"
        stroke="#359AD2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconHalfStarWhite: FunctionComponent<
  HTMLAttributes<HTMLOrSVGElement>
> = (props) => {
  return (
    <svg
      {...props}
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 18 17"
      fill="none"
    >
      <defs>
        <linearGradient id="half_grad">
          <stop offset="50%" stopColor="#FFFFFF" />
          <stop offset="50%" stopColor="currentColor" stopOpacity="1" />
        </linearGradient>
      </defs>

      <path
        d="M17 6.71429H10.8571L9 1L7.14286 6.71429H1L6 10.1429L4.07143 15.8571L9 12.2857L13.9286 15.8571L12 10.1429L17 6.71429Z"
        fill="url(#half_grad)"
        stroke="#FFFFFF"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconTrustpilot: FunctionComponent<
  HTMLAttributes<HTMLOrSVGElement>
> = (props) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.8354 8.40746H24L16.5869 13.6008L16.587 13.601L12.0049 16.8073L17.224 15.5024L16.6317 13.7337L19.4177 22L12.0046 16.8072L4.58232 22L7.42226 13.6008L0 8.39919L9.16463 8.40801L12.0046 0L14.8354 8.40746Z"
        fill="currentColor"
      />
    </svg>
  );
};
