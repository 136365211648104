import cx from 'classnames';
import { FunctionComponent, ReactNode, useRef } from 'react';

import classes from './collapsible-list.module.css';

const Description: FunctionComponent<{
  content: ReactNode;
  active: boolean;
  id: string;
}> = ({ content, active, id }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <dd
      id={id}
      style={{
        height:
          active && ref.current?.clientHeight
            ? `${ref.current?.clientHeight}px`
            : '0',
      }}
      className={cx(
        classes.collaspible,
        'overflow-hidden border-b border-neutral-30 px-4'
      )}
    >
      <div ref={ref} className="grid gap-6 py-4">
        {content}
      </div>
    </dd>
  );
};

export default Description;
