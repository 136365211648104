import cx from 'classnames';
import {
  Fragment,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { escapeRegExp } from '../../../../utils/escapeRegExp';
import Chevrelon from '../../icons/cheverlon';

import classes from './collapsible-list.module.css';
import Description from './description';
type TCollapsibleList = {
  items: Array<{
    key?: string;
    term: string;
    description: ReactNode;
  }>;
  searchKey?: string;
  activeItems?: Array<string>;
  multiOpen?: boolean /*need to be true when activeItems size is greater than 1*/;
} & HTMLAttributes<HTMLElement>;

const CollapsibleList: FunctionComponent<TCollapsibleList> = ({
  items,
  searchKey = '',
  activeItems = [],
  multiOpen = false,
  ...props
}) => {
  const [open, setOpen] = useState<Array<string>>([]);
  const regex = new RegExp(escapeRegExp(`\(${searchKey}\)`), 'ig');
  const regex1 = new RegExp(
    '(' + escapeRegExp(searchKey) + ')(?=[^>]*<)',
    'ig'
  );

  useEffect(() => {
    if (activeItems.length > 0) {
      setOpen(activeItems);
    }
  }, [activeItems]);

  return (
    <dl {...props}>
      {items.map(({ key, term, description }, index) => {
        const id = key ? key : `question-${index}`;
        const active = open ? open.indexOf(id) > -1 : false;
        return (
          <Fragment key={id}>
            <dt
              className={cx(
                classes.collaspibleItem,
                'hover:text-neutral-50',
                'text-base font-bold grid justify-between py-4 cursor-pointer items-center',
                { 'text-neutral-80': !active },
                'px-4'
              )}
              onClick={() => {
                if (active && multiOpen) {
                  setOpen(open.filter((item) => item !== id));
                } else if (active) {
                  setOpen([]);
                } else if (multiOpen) {
                  setOpen(open.concat(id));
                } else {
                  setOpen([id]);
                }
              }}
              role="button"
              aria-expanded={active}
              aria-controls={id}
              id={id}
            >
              <h3>
                {searchKey
                  ? term.split(regex).map((value, index) => {
                      if (value && value.match(regex) && searchKey) {
                        return (
                          <span key={value + index} className="text-brand-logo">
                            {value}
                          </span>
                        );
                      }
                      return <Fragment key={value + index}>{value}</Fragment>;
                    })
                  : term}
              </h3>
              <Chevrelon
                className={cx(classes.icon, 'w-4', {
                  [classes.active]: active,
                })}
              />
            </dt>
            <Description id={id} content={description} active={active} />
          </Fragment>
        );
      })}
    </dl>
  );
};

export default CollapsibleList;
