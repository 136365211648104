'use client';

import * as React from 'react';
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from 'embla-carousel-react';
import cx from 'classnames';
import useIsMobile from '../../../hooks/use-is-mobile';
import BPImage from '../v2/image';
import useTranslation from 'next-translate/useTranslation';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: 'horizontal' | 'vertical';
  setApi?: (api: CarouselApi) => void;
  viewportBasedSlidesToScroll?: {
    desktop: number;
    mobile: number;
  };
  autoplay?: boolean;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  currentSnap: number;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = 'horizontal',
      opts,
      setApi,
      plugins,
      className,
      children,
      viewportBasedSlidesToScroll,
      autoplay = false,
      ...props
    },
    ref
  ) => {
    const isMobile = useIsMobile();
    const finalOpts = React.useMemo(() => {
      if (!viewportBasedSlidesToScroll) {
        return opts;
      }

      return {
        ...opts,
        slidesToScroll: isMobile
          ? viewportBasedSlidesToScroll.mobile
          : viewportBasedSlidesToScroll.desktop,
      };
    }, [isMobile, opts, viewportBasedSlidesToScroll]);
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...finalOpts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      plugins
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const autoplayIntervalRef = React.useRef<ReturnType<typeof setInterval>>();

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setSelectedIndex(api.selectedScrollSnap());
      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext]
    );

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on('reInit', onSelect);
      api.on('select', onSelect);

      return () => {
        api?.off('select', onSelect);
      };
    }, [api, onSelect]);

    const initAutoplayInterval = React.useCallback(() => {
      if (autoplay) {
        clearInterval(autoplayIntervalRef.current);
        autoplayIntervalRef.current = setInterval(() => {
          const slides = api?.slideNodes().length;
          const perPage = finalOpts?.slidesToScroll;

          if (typeof slides === 'number' && typeof perPage === 'number') {
            if (Math.ceil(slides / perPage) == selectedIndex + 1) {
              api?.scrollTo(0);
              return;
            }
          }

          scrollNext();
        }, 5000);
      }
    }, [autoplay, selectedIndex, scrollNext]);

    const stopAutoplayInterval = React.useCallback(() => {
      if (autoplay) clearInterval(autoplayIntervalRef.current);
    }, [autoplayIntervalRef.current]);

    React.useEffect(() => {
      initAutoplayInterval();
      return () => stopAutoplayInterval();
    }, [initAutoplayInterval]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts: finalOpts,
          orientation:
            orientation ||
            (finalOpts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          currentSnap: selectedIndex,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          onMouseEnter={() => {
            if (autoplay) stopAutoplayInterval();
          }}
          onMouseLeave={() => {
            if (autoplay) initAutoplayInterval();
          }}
          className={cx('relative', className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  }
);
Carousel.displayName = 'Carousel';

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className="overflow-hidden">
      <div
        ref={ref}
        className={cx(
          'flex',
          //orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
          className
        )}
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { orientation } = useCarousel();

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cx(
        'min-w-0 shrink-0 grow-0',
        orientation === 'horizontal' ? 'pl-4' : 'pt-4',
        className
      )}
      {...props}
    />
  );
});
CarouselItem.displayName = 'CarouselItem';

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  JSX.IntrinsicElements['button']
>(({ className, ...props }, ref) => {
  const { t } = useTranslation();
  const { orientation, scrollPrev, canScrollPrev } = useCarousel();

  return (
    <button
      ref={ref}
      className={cx(
        'absolute  h-8 w-8 rounded-full',
        orientation === 'horizontal'
          ? '-left-12 top-1/2 -translate-y-1/2'
          : '-top-12 left-1/2 -translate-x-1/2 rotate-90',
        className
      )}
      disabled={!canScrollPrev}
      onClick={scrollPrev}
      {...props}
    >
      <BPImage
        src={`${process.env.PUBLIC_PREFIX}/svg/icons/arrow_back.svg`}
        height={16}
        width={16}
        layout="intrinsic"
        alt={t('common:back')}
      />
      <span className="sr-only">Previous slide</span>
    </button>
  );
});
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  JSX.IntrinsicElements['button']
>(({ className, ...props }, ref) => {
  const { t } = useTranslation();
  const { orientation, scrollNext, canScrollNext } = useCarousel();

  return (
    <button
      ref={ref}
      className={cx(
        'absolute h-8 w-8 rounded-full',
        orientation === 'horizontal'
          ? '-right-12 top-1/2 -translate-y-1/2'
          : '-bottom-12 left-1/2 -translate-x-1/2 rotate-90',
        className
      )}
      disabled={!canScrollNext}
      onClick={scrollNext}
      {...props}
    >
      <BPImage
        src={`${process.env.PUBLIC_PREFIX}/svg/icons/arrow_back.svg`}
        height={16}
        width={16}
        layout="intrinsic"
        alt={t('common:back')}
      />
      <span className="sr-only">Next slide</span>
    </button>
  );
});
CarouselNext.displayName = 'CarouselNext';

const CarouselBullets = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { api, opts, currentSnap } = useCarousel();
  const slides = api?.slideNodes().length;
  const perPage = opts?.slidesToScroll;

  if (
    slides === undefined ||
    perPage === undefined ||
    typeof perPage !== 'number'
  ) {
    return;
  }

  const bullets = new Array(Math.ceil(slides / perPage)).fill(0);

  return (
    <div
      ref={ref}
      className={cx('flex justify-center mt-4', className)}
      {...props}
    >
      {bullets.map((_, index) => (
        <button
          key={index}
          className={cx('h-3 w-3 rounded-full mx-1', {
            'bg-neutral-30 hover:bg-brand-10 ': currentSnap !== index,
            'bg-brand-logo': currentSnap === index,
          })}
          onClick={() => api?.scrollTo(index)}
        />
      ))}
    </div>
  );
});
CarouselBullets.displayName = 'CarouselBullets';

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselBullets,
};
